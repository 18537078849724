import React from "react";

import AudioPlayer from "../AudioPlayer";
import {PodcastWrap, ListenHere, ListenOn, PodcastLink, PodcastLinksRow} from "./elements";
import {TitleSm} from "../elements";
import PodcastArrow from "../../assets/icons/ArrowIcon";
import { useEffect, useState } from "react";

function PodcastBlock({audioAsset,googlePodcastLink,applePodcastLink,spotifyPodcastLink}) {

    const [embedAppleVideo, setEmbedAppleVideo] = useState(true);

    useEffect(() => {
        if(applePodcastLink?.indexOf('embed') != -1){
            setEmbedAppleVideo(true)
        }else {
            setEmbedAppleVideo(false)
        }
    },[applePodcastLink])

    return (
        <PodcastWrap>
            <ListenHere>
                <TitleSm textTransform={"uppercase"} margin={"0 0 25px 0"}>Listen here</TitleSm>
            {embedAppleVideo ? 
                 <iframe 
                    frameborder="0" height="175" style={{width:'100%', maxWidth: '1200px', minWidth: '600px', overflow: 'hidden', borderRadius: '10px'}} 
                     sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" 
                    src={applePodcastLink}>
                 </iframe>
                : 
                 <AudioPlayer source={audioAsset} />
            }
               
            </ListenHere>
            <ListenOn>
                <TitleSm textTransform={"uppercase"} margin={"0 0 40px 0"}>or Listen on</TitleSm>
                <PodcastLinksRow>
                    {spotifyPodcastLink && (
                        <PodcastLink href={spotifyPodcastLink} target="_blank">
                            Spotify
                            <PodcastArrow />
                        </PodcastLink>
                    )}
                    {!embedAppleVideo && (
                        <PodcastLink href={applePodcastLink} target="_blank">
                            Apple Podcasts
                            <PodcastArrow />
                        </PodcastLink>
                    )}
                    {googlePodcastLink && (
                        <PodcastLink href={googlePodcastLink} target="_blank">
                            Google Podcasts
                            <PodcastArrow />
                        </PodcastLink>
                    )}
            
                </PodcastLinksRow>
            </ListenOn>
        </PodcastWrap>
)
}

export default PodcastBlock;
