import React from 'react';
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";
import { Link } from "react-router-dom";

// Import Style Components
import {
    BodyRg,
    TitleXl,
    TitleLg,
    TitleMd,
    TitleSm,
    TitleXs,
    H6,
    Bold,
    UL,
    OL,
    LI,
    Quote,
    BlogLink,
    Image,
    Video
} from "../elements"; // Assuming elements are in a relative path

export const richTextOptions = (styleAttributes) => {
    // Provide default empty object for styleAttributes if none passed
    const safeStyleAttributes = styleAttributes || {};

    console.log({safeStyleAttributes})

    return {
        // 1) Use `renderText` to handle any line-break splits:
        renderText: (text) => {
            // If you literally want to detect "\n" characters:
            return text.split('\n').reduce((acc, segment, index) => {
                // If there’s a split after the first segment, insert <br /> before continuing
                if (index > 0) {
                    acc.push(<br key={`br-${index}`} />); // Use a more specific key
                }
                acc.push(segment);
                return acc;
            }, []);
        },

        // 2) Handle marks (bold, superscript, etc.)
        renderMark: {
            [MARKS.BOLD]: text => <Bold>{text}</Bold>,
            [MARKS.SUPERSCRIPT]: text => <sup>{text}</sup>,
            // Add other marks like ITALIC, UNDERLINE if needed
            // [MARKS.ITALIC]: text => <em>{text}</em>,
            // [MARKS.UNDERLINE]: text => <u>{text}</u>,
        },

        // 3) Handle block and inline nodes:
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => {
                // Handle potential iframe embeds within paragraphs
                // Check if children is just a string and contains iframe
                const firstChildValue = node?.content?.[0]?.value || '';
                if (typeof children === 'string' && children.trim().startsWith('<iframe') && children.trim().endsWith('></iframe>')) {
                     // More specific check for iframe string
                     return <div dangerouslySetInnerHTML={{ __html: children }} />;
                } else if (firstChildValue.trim().startsWith('<iframe') && firstChildValue.trim().endsWith('></iframe>')) {
                    // Check the raw node value as fallback
                     return <div dangerouslySetInnerHTML={{ __html: firstChildValue }} />;
                }

                // Render regular paragraph text
                return (
                    <BodyRg
                        color={safeStyleAttributes.color}
                        fontSize={safeStyleAttributes.fontSize}
                        textTransform={safeStyleAttributes.textTransform}
                        margin={safeStyleAttributes.margin}
                        textAlign={safeStyleAttributes.textAlign} // <-- Added textAlign
                        justifyContent={safeStyleAttributes.justifyContent}
                    >
                        {children}
                    </BodyRg>
                );
            },
            [BLOCKS.HEADING_1]: (node, children) => {
                return (
                    <TitleXl
                        color={safeStyleAttributes.color}
                        fontSize={safeStyleAttributes.fontSize}
                        textTransform={safeStyleAttributes.textTransform}
                        margin={safeStyleAttributes.margin}
                        textAlign={safeStyleAttributes.textAlign} // <-- Added textAlign
                        justifyContent={safeStyleAttributes.justifyContent}
                    >
                        {children}
                    </TitleXl>
                );
            },
            [BLOCKS.HEADING_2]: (node, children) => {
                return (
                    <TitleLg
                        color={safeStyleAttributes.color}
                        fontSize={safeStyleAttributes.fontSize}
                        textTransform={safeStyleAttributes.textTransform}
                        margin={safeStyleAttributes.margin}
                        textAlign={safeStyleAttributes.textAlign} // <-- Added textAlign
                        justifyContent={safeStyleAttributes.justifyContent}
                    >
                        {children}
                    </TitleLg>
                );
            },
            [BLOCKS.HEADING_3]: (node, children) => {
                return (
                    <TitleMd
                        color={safeStyleAttributes.color}
                        fontSize={safeStyleAttributes.fontSize}
                        textTransform={safeStyleAttributes.textTransform}
                        margin={safeStyleAttributes.margin}
                        textAlign={safeStyleAttributes.textAlign} // <-- Added textAlign
                        justifyContent={safeStyleAttributes.justifyContent}
                    >
                        {children}
                    </TitleMd>
                );
            },
            [BLOCKS.HEADING_4]: (node, children) => {
                return (
                    <TitleSm
                        color={safeStyleAttributes.color}
                        fontSize={safeStyleAttributes.fontSize}
                        textTransform={safeStyleAttributes.textTransform}
                        margin={safeStyleAttributes.margin}
                        textAlign={safeStyleAttributes.textAlign} // <-- Added textAlign
                        justifyContent={safeStyleAttributes.justifyContent}
                    >
                        {children}
                    </TitleSm>
                );
            },
            [BLOCKS.HEADING_5]: (node, children) => {
                return (
                    <TitleXs
                        color={safeStyleAttributes.color}
                        fontSize={safeStyleAttributes.fontSize}
                        textTransform={safeStyleAttributes.textTransform}
                        margin={safeStyleAttributes.margin}
                        textAlign={safeStyleAttributes.textAlign} // <-- Added textAlign
                        justifyContent={safeStyleAttributes.justifyContent}
                    >
                        {children}
                    </TitleXs>
                );
            },
            [BLOCKS.HEADING_6]: (node, children) => {
                return (
                    <H6
                        color={safeStyleAttributes.color}
                        fontSize={safeStyleAttributes.fontSize}
                        textTransform={safeStyleAttributes.textTransform}
                        margin={safeStyleAttributes.margin}
                        textAlign={safeStyleAttributes.textAlign} // <-- Added textAlign
                        justifyContent={safeStyleAttributes.justifyContent}
                    >
                        {children}
                    </H6>
                );
            },
            [BLOCKS.QUOTE]: (node, children) => {
                // Blockquotes often have distinct styling, ensure the Quote component accepts textAlign
                return (
                    <Quote
                        color={safeStyleAttributes.color}
                        fontSize={safeStyleAttributes.fontSize}
                        textTransform={safeStyleAttributes.textTransform}
                        textAlign={safeStyleAttributes.textAlign} // <-- Added textAlign
                        justifyContent={safeStyleAttributes.justifyContent}
                    >
                        {children}
                    </Quote>
                );
            },
            [BLOCKS.EMBEDDED_ASSET]: (node) => {
                // Use optional chaining safely and provide defaults
                const file = node?.data?.target?.fields?.file;
                const url = file?.url;
                const contentType = file?.contentType;
                const description = node?.data?.target?.fields?.description || 'Embedded Asset'; // Use description for alt text

                if (contentType === "video/mp4" && url) {
                    return (
                        <Video
                            // Default attributes for embedded video
                            width={'100%'}
                            loop
                            controls // Default to having controls for accessibility
                            playsInline={true}
                            // autoPlay={false} // Generally avoid autoplay unless specifically requested
                            src={url} // Use src attribute directly
                            // Add poster attribute if available in Contentful asset
                            // poster={node?.data?.target?.fields?.thumbnail?.fields?.file?.url}
                        />
                         // Source tag isn't usually needed if src is set directly on video
                    );
                } else if (url) { // Render as image if it has a URL and isn't handled as video
                    return <Image src={url} alt={description} />;
                }
                 // Return null or a placeholder if the asset is invalid/missing URL
                 return null;
            },
             [INLINES.EMBEDDED_ENTRY]: (node, children) => { // Added children parameter
                 // Assuming this is for inline images with optional links
                 const target = node?.data?.target?.fields; // Access fields directly
                 const imgUrl = target?.imageAsset?.fields?.file?.url; // Check nested structure for image URL
                 const hyperlink = target?.hyperlink; // Link URL
                 const imgDescription = target?.imageAsset?.fields?.description || 'Embedded Image'; // Alt text

                if (!imgUrl) return null; // Cannot render without an image URL

                 const imageElement = <Image src={imgUrl} alt={imgDescription} />;

                 if (hyperlink) {
                     // Check if it's an internal link (starts with /) or external
                     const isInternal = hyperlink.startsWith('/');
                     const isAnchor = hyperlink.startsWith('#');

                     if (isInternal && !isAnchor) { // Internal page link
                         return <Link to={hyperlink}>{imageElement}</Link>;
                     } else if (!isAnchor) { // External link (assume others are external)
                          return <a href={hyperlink} target="_blank" rel="noopener noreferrer">{imageElement}</a>;
                     }
                     // Fallthrough for anchor links or if no link - just render image
                 }
                 return imageElement; // Render image without link
            },
            [INLINES.ENTRY_HYPERLINK]: (node, children) => {
                const target = node?.data?.target?.fields; // Safely access fields
                if (!target) return children; // Return text if link target is invalid

                let url = '';
                 // Use sys.contentType.sys.id to identify the linked entry type
                 const linkedContentType = target?.sys?.contentType?.sys?.id;

                if (linkedContentType === 'page' && target.route) { // Check for route field
                    url = target.route;
                 } else if (linkedContentType === 'articles' && target.slug) { // Check for slug field
                     url = `/article/${target.slug}`; // Ensure leading slash if needed
                 }
                 // Add more conditions for other linkable content types

                // Render Link for internal routes, anchor otherwise (or plain text if no URL)
                if (url) {
                     return <Link to={url}>{children}</Link>;
                }
                 // Fallback if URL couldn't be determined
                 console.warn("Could not resolve URL for ENTRY_HYPERLINK", target);
                 return children;
            },
            [INLINES.ASSET_HYPERLINK]: (node, children) => {
                 const url = node?.data?.target?.fields?.file?.url;
                 if (!url) return children; // Return text if asset URL is missing

                return (
                    <a href={url} target="_blank" rel="noopener noreferrer">
                        {children}
                    </a>
                );
            },
             [BLOCKS.UL_LIST]: (node, children) => (
                 // Pass relevant style attributes if UL component supports them
                 <UL
                     // Example: Pass margin if needed, avoid passing padding directly unless UL handles it
                     margin={safeStyleAttributes.margin}
                 >
                     {children}
                 </UL>
             ),
             [BLOCKS.OL_LIST]: (node, children) => (
                 <OL
                     margin={safeStyleAttributes.margin}
                 >
                     {children}
                 </OL>
             ),
             [BLOCKS.LIST_ITEM]: (node, children) => {
                 // LI usually contains paragraphs, check nested structure
                 // The children will likely be the rendered <BodyRg> from the paragraph handler
                 // Apply LI specific styles if needed, or rely on UL/OL parent styling
                 return (
                     <LI>
                         {children}
                     </LI>
                 );
             },
            [INLINES.HYPERLINK]: (node, children) => {
                 const uri = node?.data?.uri;
                 if (!uri) return children; // Return text if URI is missing

                // Basic check for external links
                 let isExternal = false;
                 try {
                     const parsedUrl = new URL(uri);
                     isExternal = ['http:', 'https:'].includes(parsedUrl.protocol);
                 } catch (_) {
                     // If parsing fails, assume it's internal (e.g., /path, #anchor) or potentially mailto:
                     isExternal = uri.startsWith('mailto:') || uri.startsWith('tel:');
                 }

                 const target = isExternal ? '_blank' : '_self';
                 const rel = isExternal ? 'noopener noreferrer' : undefined;

                 // Use BlogLink or a standard 'a' tag
                 // If BlogLink is just a styled 'a', this is fine
                 return (
                     <BlogLink href={uri} target={target} rel={rel}>
                         {children}
                     </BlogLink>
                 );
            },
            // Add handlers for other BLOCKS/INLINES as needed
            // e.g., HR, EMBEDDED_ENTRY (block level)
            [BLOCKS.HR]: () => <hr style={{ margin: '2em 0' }} />, // Example HR handler

        }, // end renderNode
    }; // end return
};