import PardotForm from 'components/PardotForm'
import React, { useEffect, useRef, useState } from 'react'
import {
	BlogImage,
	BlogText,
	Section,
	Sections,
	DetailWrap,
	BlogWistiaVideo,
	BlogLeftVideo,
	SectionVideo,
	SectionVideoDesc,
	TextWithImage
} from './elements'

import AuthorInfo from '../AuthorInfo'
import RichText from 'components/RichText'
import WistiaPlayer from "../../../components/WistiaPlayer/WistiaPlayer"
import { Caption, TitleMd } from "../../../components/elements"
import BlogSlider from "../BlogSlider"
import WistiaPopupEmbed from "../../../components/WistiaPopupEmbed"
import { Link } from "react-router-dom"
import PodcastBlock from "../../../components/PodcastBlock"
import DownloadSection from 'components/DownloadArea'
import TranscriptAndContentOutline from '../TranscriptAndContentOutline'
import Quotes from '../Quotes'

const renderSection = (section, quoteWidth, uniqueKey) => {
	// --- Ensure section has elements ---
	if (section.ctype !== 'region') {
		// Wrap non-region elements (handle potential undefined section.placement here too)
		if (!['image', 'textArea', 'video', 'imageSlider', 'audio', 'downloadArea', 'transcriptAndContentOutline', 'quotes', 'pardotForm'].includes(section.ctype)) {
			return <div key={uniqueKey}></div>; // Ignore unknown types
		}
		section = {
			elements: [section],
			placement: section.placement // Pass original placement through
		};
	}
	if (!section.elements?.length) return <div key={uniqueKey}></div>;

	// --- Calculate Size and Placement (with Left-Justified Default) ---
	let size = '';
	let placement = ''; // This will determine margin/alignment: 'center', 'flex-start', 'flex-end'

	// Use optional chaining for safety when accessing section.placement
	switch (section?.placement) {
		case 'Centered':
			size = window.innerWidth <= 1440 ? '800px' : '900px';
			placement = 'center';
			break;
		case 'Full-Width':
			size = '100%';
			placement = 'center'; // Full width sections are centered by nature of taking full space
			break;
		case 'Left-Justified':
			size = quoteWidth + 'px';
			placement = 'flex-start'; // Use flexbox alignment term
			break;
		case 'Right-Justified':
			size = quoteWidth + 'px';
			placement = 'flex-end'; // Use flexbox alignment term
			break;
		// --- Default Case ---
		default:
			// If placement is undefined, null, or any other value, default to LEFT alignment.
			// You might want a specific default size here too.
			// Using quoteWidth like Left/Right justified:
			size = quoteWidth + 'px';
			// Or using the 'Centered' size as default:
			// size = window.innerWidth <= 1440 ? '800px' : '900px';
			placement = 'flex-start'; // Default to left alignment
			// Optional: Log a warning if an unexpected placement value is encountered
			if (section?.placement !== undefined && section?.placement !== null) {
				console.warn(`BlogDetail: Unknown section placement value "${section.placement}". Defaulting to left-justified.`);
			}
			break;
	}

	// --- Anchor Link Logic ---
	// Use optional chaining and ensure anchor exists before getting substring
	const anchorValue = section.elements[0]?.anchor;
	const ank = (anchorValue && anchorValue.startsWith('#')) ? anchorValue.substring(1) : '';

	// --- Render Section ---
	return (
		<Section
			id={ank}
			key={`${section?.cfid || section.elements[0]?.cfid || 'section'}-${uniqueKey}`}
			placement={placement} // Pass the calculated placement ('flex-start' will be default)
			size={size}         // Pass the calculated size
			marginLeft={section.marginLeft} // Pass explicit margins if they exist
			marginRight={section.marginRight}
		>
			{/* Use element's cfid for more stable keys if available */}
			{section.elements.map((e, k) => renderElement(e, `${e?.cfid || 'element'}-${k}`))}
		</Section>
	);
};

// --- IMPORTANT ---
// The `renderElement` function and the `Section` styled-component definition
// remain the same as in the previous answer where we fixed the placement logic.
// The change is *only* within the `switch` statement's `default` case above.

const renderElement = (e, uniqueKey) => {
	// Render audio (podcast) blocks
	if (e.ctype === 'audio') {
		return <PodcastBlock {...e} key={uniqueKey} />
	}

	// Render Pardot forms
	if (e.ctype === 'pardotForm') {
		return (
			<PardotForm
				height={e.height}
				key={uniqueKey}
				pardotLink={e.pardotUrl}
			/>
		)
	}

	// Render transcripts & content outlines
	if (e.ctype === 'transcriptAndContentOutline') {
		return (
			<TranscriptAndContentOutline
				key={uniqueKey}
				title={e.title}
				transcript={e.transcript}
				transcriptAndContent={e.transcriptAndContent}
			/>
		)
	}

	// Render download area
	if (e.ctype === 'downloadArea') {
		return <DownloadSection key={uniqueKey} {...e} />
	}

	// Render quotes
	if (e.ctype === 'quotes') {
		return (
			<Quotes
				key={uniqueKey}
				title={e.title}
				text={e.text}
				attribution={e.attribution}
			/>
		)
	}

	// Render text areas (with optional images)
	if (e.ctype === 'textArea') {
		const hasImage = e.image
		if (hasImage) {
			return (
				<BlogText key={`${e.cfid}-${uniqueKey}`}>
					<TextWithImage>
						<div className="text-content">
							<RichText body={e.text} options={{ color: 'black' }} />
						</div>
						<img
							src={e.image}
							alt={e.imageAlt || 'Illustrative Image'}
						/>
					</TextWithImage>
				</BlogText>
			)
		}
		return (
			<BlogText key={`${e.cfid}-${uniqueKey}`}>
				<RichText body={e.text} options={{ color: 'black' }} />
			</BlogText>
		)
	}

	// Render images (with optional hyperlink)
	if (e.ctype === 'image') {
		if (e?.hyperlink) {
			if (e.hyperlink.charAt(0) === '/') {
				return (
					<Link key={uniqueKey} to={e?.hyperlink}>
						<BlogImage src={e.imageAsset} alt="Body" />
					</Link>
				)
			} else {
				return (
					<a
						key={uniqueKey}
						href={`${e?.hyperlink}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						<BlogImage src={e.imageAsset} alt="Body" />
					</a>
				)
			}
		} else {
			return (
				<BlogImage
					key={`${e.imageAsset}-${uniqueKey}`}
					src={e.imageAsset}
					alt="Body"
				/>
			)
		}
	}

	// Render image sliders
	if (e.ctype === 'imageSlider') {
		return <BlogSlider key={uniqueKey} slides={e?.images} />
	}

	// Render video
	if (e.ctype === 'video') {
		let arr = e?.videoUrl?.split('/')
		let isChannel = arr?.includes('channel')

		// If there's a separate description
		if (e.description && e.videoUrl) {
			return (
				<SectionVideo key={uniqueKey}>
					<BlogLeftVideo>
						<WistiaPopupEmbed
							playButton={true}
							customClass="wistia_custom"
							hashedId={arr[arr?.length - 1]}
							isChannel={isChannel}
						/>
						<Caption>{e.headline}</Caption>
					</BlogLeftVideo>
					<SectionVideoDesc>
						<RichText body={e.description} />
					</SectionVideoDesc>
				</SectionVideo>
			)
		}
		// Basic video embed
		else if (e.videoUrl) {
			return (
				<BlogWistiaVideo key={uniqueKey}>
					<TitleMd lineHeight="1.15">{e.headline}</TitleMd>
					<div>
						<WistiaPlayer
							controlsVisibleOnLoad={false}
							customClass="wistia_custom"
							hashedId={arr[arr.length - 1]}
							isChannel={isChannel}
						/>
					</div>
				</BlogWistiaVideo>
			)
		}
	}

	// Ignore any other ctype not handled above
	return <div key={uniqueKey} />
}

function BlogDetail({ authors, body, sections }) {
	const sectionRef = useRef(null)
	const [quoteWidth, setQuoteWidth] = useState(1200)
	let timeout = false

	const resizeObserver = () => {
		clearTimeout(timeout)
		timeout = setTimeout(() => {
			if (sectionRef.current) {
				let windowWidth =
					window.innerWidth >= 2500 - '30%'
						? 2420
						: sectionRef.current.clientWidth

				let sectionWidth =
					windowWidth - (window.innerWidth <= 1440 ? 800 : 900)
				let dividedWidth = sectionWidth / 2
				setQuoteWidth(
					dividedWidth + (window.innerWidth <= 1440 ? 800 : 900)
				)
			}
		}, 750)
	}

	useEffect(() => {
		if (sectionRef.current) {
			let windowWidth =
				window.innerWidth >= 2500 - '30%' ? 2420 : sectionRef.current.clientWidth

			let sectionWidth =
				windowWidth - (window.innerWidth <= 1440 ? 800 : 900)
			let dividedWidth = sectionWidth / 2
			setQuoteWidth(
				dividedWidth + (window.innerWidth <= 1440 ? 800 : 900)
			)
		}

		window.addEventListener('resize', resizeObserver, true)
		return () => {
			window.removeEventListener('resize', resizeObserver)
		}
	}, [])

	if (sections) {
		return (
			<Sections ref={sectionRef}>
				{sections.map((s, i) => renderSection(s, quoteWidth, i))}
				<Section className="author-section" size="900px" placement="center">
					<AuthorInfo authors={authors} />
				</Section>
			</Sections>
		)
	} else if (body) {
		return (
			<DetailWrap>
				<RichText body={body} options={{ color: 'black' }} />
				<Section marginLeft="0" marginRight="0" placement="center">
					<AuthorInfo authors={authors} />
				</Section>
			</DetailWrap>
		)
	}

	return <div></div>
}

export default BlogDetail
