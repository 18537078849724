import React, { useEffect, useRef } from 'react';
import Slider from "react-slick";
import { Divider } from 'components/MediaCarousel/elements';
import {
    SectionTitle,
    BodySm,
    BodyRg, Row, Col, TitleMd, TitleXl,
} from 'components/elements';
import {
    JobsSectionWrap,
    DividerLg,
    JobsGrid,
    JobsGridContent,
    JobsSectionBottom,
    CtaContainer
} from './elements';
import JobDarkCard from "../../../components/JobDarkCard";

// Slick Slider
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LocationCardDark from "../../../components/LocationDarkCard";
import ViewAll from "../../../components/ViewAll";
import { Link, useLocation } from 'react-router-dom';

/**
 * Normalizes a location name so that "City, Country" is consistently
 * "ShorterName, LongerName" for city-level comparisons.
 * Leaves "Remote - X" style strings alone.
 * Handles different remote formats.
 */
const normalizeLocationName = (name) => {
    if (!name || typeof name !== 'string') return ''; // Handle null/undefined/non-string input

    const trimmedName = name.trim();
    const lowerCaseName = trimmedName.toLowerCase();

    // Handle various "Remote" formats consistently
    if (lowerCaseName.startsWith("remote")) {
        // You might want a single "Remote" category or keep specifics like "Remote - UK"
        // Let's standardize to just "Remote" for simplicity in grouping, unless specific distinction is needed.
        // Example: If "Remote - UK" and "Remote - USA" should be separate cards, adjust this.
        // For now, unifying all remotes:
         return "Remote";
       // If you need specific remote locations:
       // if (lowerCaseName.includes("remote - usa")) return "Remote - USA";
       // if (lowerCaseName.includes("remote - uk")) return "Remote - UK";
       // if (lowerCaseName.includes("remote - canada")) return "Remote - Canada";
       // // Add other specific remote patterns if necessary
       // if (lowerCaseName === "remote") return "Remote";
    }


    const parts = trimmedName.split(",").map((part) => part.trim());
    if (parts.length === 2) {
        const [part1, part2] = parts;
        // Compare lengths to maintain consistent City, Country
        // Ensure we don't produce "undefined, undefined" if parts are empty
        if (part1 && part2) {
            return part1.length <= part2.length ? `${part1}, ${part2}` : `${part2}, ${part1}`;
        }
    }

    // If it's just a single part name (e.g., "London" without country) or doesn't match City, Country
    return trimmedName; // Return the original trimmed name
};


function JobsSection(props) {
    const data = props.jobsSectionData ? props.jobsSectionData : {};
    const sliderRef = useRef(null);
    const location = useLocation();

    // --- Department Sorting Logic (unchanged) ---
    let departments = [];
    let departmentPriority = ["Integrated Communications", "Advertising,", "Activation", "Advanced Analytics, Targeting & Engagement", "Medical", "Creative", "Corporate"];

    let sortedDepartments = data?.departments?.slice().sort((a, b) => {
        return departmentPriority.indexOf(a.name) - departmentPriority.indexOf(b.name);
    }) || []; // Ensure it's an array

    let topThree = sortedDepartments.slice(0, 3).sort((a, b) => b.count - a.count);
    let bottomThree = sortedDepartments.slice(3).sort((a, b) => b.count - a.count);
    departments = [...topThree, ...bottomThree];

    // --- Location Hash Logic (unchanged) ---
    useEffect(() => {
        const hash = location.hash.toLowerCase();
        if (sliderRef.current) { // Check if sliderRef is available
            if (hash === "#browselocation") {
                sliderRef.current.slickGoTo(1);
            }
            if (hash === "#careerfocus") {
                sliderRef.current.slickGoTo(0);
            }
        }
    }, [location]);


    const settings = {
        infinite: false,
        centerSpacing: "0",
        accessibility: true,
        margin: "20px",
        slidesToShow: 1,
        draggable: false,
        autoplay: false,
        speed: 1000,
        slidesToScroll: 1,
        initialSlide: 0,
        dots: true,
        customPaging: (slider, i) => {
            // Use index i to determine label
            const label = i === 0 ? 'CAREER FOCUS' : 'LOCATION';
            return <button aria-label={label} />;
        },
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 0,
                settings: { speed: 600 }
            },
            {
                breakpoint: 991,
                settings: { speed: 1000 }
            },
        ]
    };

    // --- Calculate Correct Location Counts (unchanged) ---
    const calculateLocationCounts = (jobs) => {
        if (!jobs || !Array.isArray(jobs)) {
            return {}; // Return empty map if no jobs data
        }
        let cityJobsMap = {};
        jobs.forEach((job, idx) => {
            // Determine the list of locations for the job
            let locsForJob = [];
            // Prioritize job.locations if it's a non-empty array or string
            if (Array.isArray(job.locations) && job.locations.length > 0) {
                locsForJob = job.locations;
            } else if (typeof job.locations === 'string' && job.locations.trim() !== '') {
                locsForJob = job.locations.split(';').map(loc => loc.trim()).filter(Boolean); // Split, trim, and remove empty strings
            } else if (job.primaryLocation) { // Fallback to primaryLocation if job.locations is missing/empty
                locsForJob = [job.primaryLocation];
            }

            // Process each location string for the job
            const uniqueNormalizedLocsForJob = new Set(); // Ensure a job isn't counted multiple times for the same normalized location if listed redundantly
            locsForJob.forEach((loc) => {
                if (!loc || typeof loc !== 'string') return; // Skip invalid entries
                const normalizedLoc = normalizeLocationName(loc.trim());

                if (!normalizedLoc) return; // Skip if normalization results in empty string

                 // Track unique normalized locations for *this specific job*
                if (!uniqueNormalizedLocsForJob.has(normalizedLoc)) {
                    uniqueNormalizedLocsForJob.add(normalizedLoc);

                    if (!cityJobsMap[normalizedLoc]) {
                        cityJobsMap[normalizedLoc] = new Set();
                    }
                    cityJobsMap[normalizedLoc].add(job.id || idx); // Add job ID (preferable) or index to the set
                }
            });
        });

        // console.log("Raw Jobs Data:", jobs);
        // console.log("Calculated City Job Counts Map:", cityJobsMap);

        // Convert map to array: { name: string, count: number }
        return Object.entries(cityJobsMap).map(([name, jobSet]) => ({
            name,
            count: jobSet.size,
            // jobIndexes: [...jobSet], // Include if needed elsewhere
        }));
    };

    // Calculate counts using the raw job data (ASSUMING data.jobs is passed in props)
    const correctLocationCounts = calculateLocationCounts(data.jobs);
    // console.log("Correct Location Counts Array:", correctLocationCounts);

    // --- *** NEW: Merge, Deduplicate, and Prioritize Location Data *** ---
    const finalLocationsData = (() => { // Use IIFE to encapsulate logic
        const locationMap = new Map(); // Use a Map for efficient lookup and deduplication

        // 1. Process all location definitions and merge with counts
        (data.locations || []) // Ensure data.locations is an array
            .forEach(locDefinition => {
                // Normalize the name from the definition list for matching
                const normalizedDefName = normalizeLocationName(locDefinition.name);
                if (!normalizedDefName) return; // Skip if definition name is invalid

                // Find the corresponding calculated count object
                const countData = correctLocationCounts.find(c => c.name === normalizedDefName);
                const count = countData ? countData.count : 0;

                // Create the potential location object
                const potentialLoc = {
                    ...locDefinition, // Keep original image, description, etc.
                    name: normalizedDefName, // Use the normalized name for consistency and display
                    count: count, // Use the CORRECT calculated count
                    link: `/job-category/l:${encodeURIComponent(normalizedDefName)}`
                };

                // 2. Deduplicate and Prioritize:
                const existingLoc = locationMap.get(normalizedDefName);

                if (!existingLoc) {
                    // If this normalized location isn't in the map yet, add it
                    locationMap.set(normalizedDefName, potentialLoc);
                } else {
                    // If it exists, check prioritization: Prefer the one with a description
                    // If the NEW one has a description and the OLD one doesn't, replace it.
                    if (potentialLoc.description && !existingLoc.description) {
                        locationMap.set(normalizedDefName, potentialLoc);
                    }
                    // Otherwise, keep the existing one (either it already had a description,
                    // or neither has one, so the first one encountered is kept)

                    // IMPORTANT: Ensure the count from the *latest* calculation is used,
                    // even if we keep the older metadata (description/image).
                    // This handles cases where count might differ slightly if logic changes.
                    // We always want the count from `correctLocationCounts`.
                    // So, update the count on the existing entry just in case.
                    existingLoc.count = count; // Update count on the one we are keeping
                }
            });

        // Add locations from counts that might not have a definition entry
        correctLocationCounts.forEach(countData => {
            if (!locationMap.has(countData.name) && countData.count > 0) {
                 // This location was found in jobs but not in `data.locations`. Create a basic entry.
                locationMap.set(countData.name, {
                    name: countData.name,
                    count: countData.count,
                    description: '', // No description available
                    image: null, // No image available
                    link: `/job-category/l:${encodeURIComponent(countData.name)}`
                });
            }
        });


        // 3. Convert map values back to an array
        const uniqueLocations = Array.from(locationMap.values());

        // 4. Filter out locations with 0 jobs
        const filteredLocations = uniqueLocations.filter(loc => loc.count > 0);

        // 5. Optional: Sort the final list (e.g., by count)
        const sortedLocations = filteredLocations.sort((a, b) => b.count - a.count);

        // console.log("Final Processed Locations Data:", sortedLocations);
        return sortedLocations;
    })(); // Immediately invoke the function

    return (
        <JobsSectionWrap id={props.id} className="job openings section">
            <JobsGrid>
                {/* --- Header Content (unchanged) --- */}
                <Divider />
                <SectionTitle padding="0 30px 60px">
                    <BodySm textTransform="uppercase">{data.label}</BodySm>
                    <TitleXl textTransform="none">We're gonna need a bigger office.</TitleXl>
                    <TitleMd> Luckily, we're flexible.</TitleMd>
                    <BodyRg>
                        At Real Chemistry, we approach our work with Real HART – that is, our Real Hybrid and Regional Teams approach. We believe we are best together, and our workplace strategy fosters connection and collaboration in person while also supporting flexibility for our people. We're a unique mix of talent and grit with a desire to make healthcare better sprinkled on top. Yet there isn't one list of characteristics that describes all of us, because we each bring something different to this ever-growing table. That's what makes our jobs more fun, and why we do them so well.
                    </BodyRg>
                    <BodyRg>
                        So, how does this translate for our colleagues working across our global locations? For those who are within an hour of one of our offices, we expect attendance in the office two days per week, either at a Real Chemistry office or onsite with clients.  We are not looking for attendance for the sake of attendance but believe that the opportunity to coordinate in-office team meetings, 1:1 meetings with managers, taking advantage of on-site learning, and connecting with client partners is critical to delivering on our purpose of making healthcare what it should be.  And whether you're working from one of our hubs and coming into the office two days per week, or part of our Regional teams and reconnecting once a quarter for collaboration, culture, and learning opportunities, our teams are here to provide support for each colleague with their own #LifeatRealChem experience.
                    </BodyRg>
                    <CtaContainer>
                        <ViewAll link={'/job-category'} title={"SEE OPEN ROLES"} borderColorHover="#fff" borderColor="#000" titleColor="#000" hoverBackground="#000" svgColor="#000" width="290px" height="44px" />
                    </CtaContainer>
                </SectionTitle>
                <DividerLg />

                {/* --- Slider Content --- */}
                <JobsGridContent id="careerfocus">
                    {/* Check if departments or locations exist before rendering slider */}
                    {(departments.length > 0 || finalLocationsData.length > 0) ? (
                        <Slider {...settings} ref={sliderRef}>
                            {/* Slide 1: Departments */}
                            <Row>
                                {
                                    departments.map((dept, index) => {
                                        // Ensure dept.name is valid before creating link/card
                                        if (!dept || !dept.name || dept.name === "undefined") return null;
                                        let lnk = '/job-category/c:' + encodeURIComponent(dept.name); // URL-encode name
                                        return (
                                            <Col key={`dept-${index}`}>
                                                <JobDarkCard
                                                    jobTitle={dept.name}
                                                    count={dept.count} // Assuming dept.count is correct
                                                    link={lnk}
                                                    isLocation={false}
                                                    jobDesc={dept.description}
                                                />
                                            </Col>
                                        );
                                    })
                                }
                            </Row>

                            {/* Slide 2: Locations */}
                            <Row className="locationRow">
                                {
                                    finalLocationsData.map((loc, index) => {
                                        // Link is already constructed in finalLocationsData
                                        // Ensure loc object and name are valid
                                        if (!loc || !loc.name) return null;
                                        let lnk = loc.link;
                                        return (
                                            <Col key={`loc-${loc.name}-${index}`}> {/* Use a more unique key */}
                                                <LocationCardDark
                                                    jobType={loc.name} // Display the normalized name
                                                    count={loc.count}  // Display the CORRECT count
                                                    link={lnk}
                                                    isLocation={true} // Should be true for LocationCardDark
                                                    jobDesc={loc.description}
                                                    locImg={loc.image}
                                                />
                                            </Col>
                                        );
                                    })
                                }
                            </Row>
                        </Slider>
                    ) : (
                        <p>No job data available to display categories or locations.</p> // Fallback if no data
                    )}
                    {/* --- Footer Content (unchanged) --- */}
                    <JobsSectionBottom>
                        <ViewAll link={'/job-category'} title={"SEE ALL OPEN ROLES"} borderColorHover="#fff" borderColor="#000" titleColor="#000" hoverBackground="#000" width="300px" height="44px" svgColor="#000" />
                        <BodyRg>
                            <Link to={"/scam-alert"}><span className={'magenta'}>Read more</span></Link> about our commitment to safe online recruiting.
                        </BodyRg>
                    </JobsSectionBottom>
                </JobsGridContent>
            </JobsGrid>
        </JobsSectionWrap>
    );
}

export default JobsSection;