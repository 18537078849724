import React, {useEffect} from 'react'
import {
    BodySm,
    Col4,
    Row,
    SectionTitle,
    WideScreenContainer, TitleXl,
} from 'components/elements'

import {
	CareersPageWrap,
	WorkCardsSection,
	CorpSection, 
	VideoCopyContainer,
	FlexContainer,


} from './elements'
import PurpleAnimHeader from 'components/PurpleAnimHeader';
import ViewAll from 'components/ViewAll';
import { BlogWistiaVideo } from 'pages/blog-details/BlogDetail/elements';
import WistiaPlayer from 'components/WistiaPlayer/WistiaPlayer';
// Slick Slider
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PagedList from 'components/PagedList'
import TabbedGrid from 'components/TabbedGrid'
import {r3cPageContent} from 'constants/index'
import {findContent, getImageDimensions, isEmptyObject} from 'generalHelpers'
import RichText from 'components/RichText'
import CareerAccordion from 'components/CareerAccordions';
import AwardCard from 'components/AwardCard'
import JobsSection from './JobsSection'
import FooterAnimation from 'components/FooterAnimation'
import LoadingHoc from '../../hoc/LoadingHOC'
import {Helmet} from "react-helmet";
import useRememberScrollPosition from "../../hooks/useRememberScrollPosition";
import DarkAccordion from "../../components/DarkAccordion";
import { Divider } from './JobsSection/elements';


function Careers(props) {

	const {setLoading} = props		 //receive the loading trigger change function from the HOC

	const [heroSectionData, setHeroSectionData] = React.useState([])
	const [awardsSectionData, setAwardsSectionData] = React.useState([])
	const [benefitsSectionData, setBenefitsSectionData] = React.useState([])
	const [corpRespSectionData, setCorpRespSectionData] = React.useState([])
	const [corpStructSectionData, setCorpStructSectionData] = React.useState([])
	const [jobsSectionData, setJobsSectionData] = React.useState([])
	const [textSectionData, setTextSectionData] = React.useState({})
	const [topOpenings, setTopOpenings] = React.useState([])
	const [awardsTitle, setAwardsTitle] = React.useState([])
	const [ourCulture, setOurCulture] = React.useState([])
	const [getToKnowUs, setGetToKnowUs] = React.useState([])
	const [getToKnowUsTitle, setGetToKnowUsTitle] = React.useState(undefined)

	const [seoData, setSeoData] = React.useState({})

	React.useEffect(() => {
		fetch(r3cPageContent + '?route=/careers').then((response) => response.json()).then((page) => {
			let seo = page.seo;
			let image = page?.seo?.image?.split('.')

			page?.seo?.image ? getImageDimensions(page?.seo?.image, function (width, height) {
				setSeoData({
					...seo,
					mimeType: image[image.length - 1],
					seoImageHeight: height,
					seoImageWidth: width
				})

			}) : setSeoData(seo)


			setHeroSectionData(findContent(page.contents, 'heroArea'))
			setTextSectionData(findContent(page.contents, 'textArea'))
			setGetToKnowUs(findContent(page.contents, 'twoColumnCopyImage'))
			page.contents.map((item) => {
				if(item.ctype === 'ourCultureSlider'){
						setOurCulture((prev) => [...prev, item])

				}

			})
	
			let data = page.contents[1]
			let sliderImages = []
			for (let i = 0; i < data?.rightColumnContent?.length; i++)
			sliderImages.push(data.rightColumnContent[i].imageAsset)
			
			
			setAwardsSectionData(findContent(page.contents, 'region', 1))
			setBenefitsSectionData(findContent(page.contents, 'iconCardArea'))
			setCorpRespSectionData(findContent(page.contents, 'plusCards'))
			setCorpStructSectionData(findContent(page.contents, 'cards'))
			// setAwardsTitle(findContent(page.contents, 'cards'))
			const jobSectionData = findContent(page.contents, 'categoryCards')

			setJobsSectionData({...jobSectionData, jobs: page?.jobs})
			setTopOpenings(page.topOpenings)
			setLoading(false)
		})
		// eslint-disable-next-line
	}, [])


	useEffect(() => {		
		setGetToKnowUsTitle(getToKnowUs?.title?.replace(/([.?!])\s*(?=[A-Z])/g, "$1|").split("|"))
	},[getToKnowUs])


	useEffect(()=>{
		// eslint-disable-next-line
		let result = awardsSectionData?.elements?.map(a => {
			if(a.ctype === 'cards'){
				setAwardsTitle(a)
			}
		});
	},[awardsSectionData])




	useRememberScrollPosition('careers');


	const openInNewTab = (url) => {
		const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
		if (newWindow) newWindow.opener = null
	}

	
	return (
		<CareersPageWrap className="pageWrapper">
			<Helmet>
				<title>{seoData.title}</title>
				<link rel="canonical" href={window.location.pathname}/>
				<meta name="description" content={seoData.description}/>
				<meta name="og:description" content={seoData.description}/>
				<meta name="og:title" content={seoData.title}/>
				<meta name="og:url" content={window.location.protocol + "//" + window.location.host + window.location.pathname}/>
				<meta name="og:type" content="website"/>
				<meta name="og:site_name" content="Real Chemistry"/>
				<meta name="og:image" content={window.location.protocol + seoData.image}/>
				<meta name="og:image:type" content={"image/" + seoData?.mimeType}/>
				<meta name="og:image:width" content={seoData?.seoImageWidth}/>
				<meta name="og:image:height" content={seoData?.seoImageHeight}/>
			</Helmet>
			<PurpleAnimHeader
                sectionLabel={heroSectionData.label}
                sectionTitle={heroSectionData.textArea}
				heroIntro={textSectionData.text}
				headerSplit={"1"}
                backgroundImage={"/images/career-header.svg"}
				backgroundImgMbl={"/images/career-head-mbl.svg"}
				backgroundImgTitle={""}
                header={"We’ve got work to do"}
				introCopy={"Our singular mission is to be the difference between what healthcare is and what it should be. Real Chemistry encourages diverse, best-in-field experts to collaborate freely. We don’t take that responsibility lightly, but we also don’t take ourselves too seriously. In short, we care—about the patients we're here to help, and most of all, about each other. If that sounds like you, we’ve got a job for you. "}
                titleMdPadding={"5% 0 0"}
				maxWidth={"45%"}
				marginRight={"8%"}
				cta={true}
            />
	<WideScreenContainer style={{overflow: 'hidden'}}>
			 <VideoCopyContainer id="culturevideo">
				<BlogWistiaVideo>
					<WistiaPlayer
						controlsVisibleOnLoad={false}
						customClass="wistia_custom"
						hashedId={'s3hqjyms4x'}
						isChannel={false}
					/>
				</BlogWistiaVideo>
				<TitleXl fontWeight="900">
					Simply put – our people and purpose make <span style={{color: '#6900FF'}}>#LifeatRealChem</span> great.
				</TitleXl>
            </VideoCopyContainer>

			<FlexContainer>
				<TitleXl fontWeight="900">
					{getToKnowUsTitle?.map((t, i) => <p style={{display: i === 1 ? 'inline-block' : undefined}}>{t}</p>)}<span style={{color: '#D12FFF'}}>:</span>
				</TitleXl>
				<div>
					<img src={getToKnowUs?.columnMedia} alt="May 2023-May 2024 Great Place to Work Certified" />
				</div>
			</FlexContainer>

			{ourCulture && (
				ourCulture.map((c, index) => (
					<CareerAccordion key={index} data={c} />
				))
			)}

			
			{/* Jobs Section */}
				{
					!isEmptyObject(jobsSectionData) &&
					<JobsSection  id="browseLocation" jobsSectionData={jobsSectionData} />
				}


				<TabbedGrid gridData={benefitsSectionData}/>
			</WideScreenContainer>

			{/* Corporate Section */}
			<CorpSection>
				<WideScreenContainer>
					<Divider/>
					<SectionTitle padding="0 30px 0" className="corporate-title">				
						<BodySm textTransform="uppercase">{corpStructSectionData.title}</BodySm>
						<RichText body={corpStructSectionData.detail}/>
					</SectionTitle>
				</WideScreenContainer>
				{
					corpRespSectionData?.items?.map((item, index) => {
						return (
							<DarkAccordion
								margin={'0 0 24px 0'}
								key={'rspcor-' + index}
								mainTitle={item.label}
								richText={item.detail}
								ctaLink={item.ctaLink}
								ctaText={item.ctaText}
							/>
						)
					})
				}
			</CorpSection>


			<WorkCardsSection className="nextSection" id="awards">
				<SectionTitle padding="56px 30px" borderBottom={"1px solid #707070"}>
					<BodySm className={'bodySm'} textTransform="uppercase" marginBottom={'15px'}>{awardsTitle.title}</BodySm>
					<RichText body={awardsTitle.detail}/>
				</SectionTitle>
				<Row>
					{awardsSectionData && awardsSectionData.elements && awardsSectionData.elements.map((item, index) => {
						return (item.ctype !== 'cards') ? index < 3 && <Col4 key={'rcAward' + index}>
							<AwardCard
								awardLogo={item.icon}
								borderLeft="none"
								borderTop="none"
								borderRight={index === 2 ? "none" : "1px solid #707070"}
								height="265.74px"
								title={item.title}
								date={item.date}
								url={item.url}
								onClick={() => openInNewTab(item.url)}
							/>
						</Col4> : ''
					})}
				</Row>
				<Row>
					{awardsSectionData && awardsSectionData.elements && awardsSectionData.elements.map((item, index) => {
						return (item.ctype !== 'cards') ? index >= 3 && <Col4 key={'rcAward' + index}>
							<AwardCard
								awardLogo={item.icon}
								borderLeft="none"
								borderTop="none"
								borderRight={index === 5 ? "none" : "1px solid #707070"}
								height="265.74px"
								title={item.title}
								date={item.date}
								url={item.url}
								onClick={() => openInNewTab(item.url)}
							/>
						</Col4> : ''
					})}
				</Row>
			</WorkCardsSection>

			<WideScreenContainer>
				{topOpenings?.length > 0 &&
				<PagedList
					fromInsights={false}
					title="Top Openings"
					icon="/images/News-icon-black.svg"
					rows={topOpenings}
					borderBottom="none"
					className="openings"
				/>
				}
				<SectionTitle className={"openPostionSec"} padding="64px 104px 113px">
					<BodySm className={"bodySm"} textTransform="uppercase" marginBottom={'15px'}>SOUND INTERESTING?</BodySm>
					<TitleXl className="jobOppCopy">See all job opportunities</TitleXl>
					<ViewAll title={"VIEW OPEN ROLES"} borderColor="#000" borderColorHover="#fff" titleColor="#000" hoverBackground="#000" width="300px" height="44px" svgColor="#000" link={"/job-category"} />
				</SectionTitle>

			</WideScreenContainer>

			<FooterAnimation
				svgDesktop="/images/purple-footer.svg"
				svgMobile="/images/purple-footer-mbl.svg"
				borderTop="none"
			/>
		</CareersPageWrap>
	)
}

// Wrap the component with LoadingHOC and send in the component.

export default LoadingHoc(Careers)
