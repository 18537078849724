import React from 'react'
import { Link } from 'react-router-dom';

import {
    Row,
    Col5,
    Col7,
    TitleContainer,
    TitleXs,
    TitleLg,
    TitleXl,
    BackArrow,
    BodySm,
    WideScreenContainer,
    TitleSm,
} from 'components/elements'

import {
    FilterCard,
    JobCategoryPageWrap,
    CategoryFilterSection,
    CategorySectionRight,
    SearchGroup,
    SearchBtn,
    SearchInput,
    SearchBox,
    BadgesRow,
    ResetButton,
    BadgeContainer,
    HeaderContainer,
    ViewAllContainer
} from './elements'

import ViewAll from 'components/ViewAll'
import JobPostCard from 'components/JobPostCard'

import { DynamicContent, r3cPageContent } from 'constants/index'
import LoadingHoc from "../../hoc/LoadingHOC";
import BackArrowSvg from "../../assets/icons/BackArrowSvg";
import FooterAnimation from "../../components/FooterAnimation";
import CollapsibleCheckboxArea from "../../components/CollapsibleCheckboxArea";

/** 
 * Normalizes a location name so that "City, Country" is consistently
 * "ShorterName, LongerName" for city-level comparisons. 
 * Leaves "Remote - X" style strings alone.
 */
/**
 * Normalizes a location name consistently for filtering and display.
 * - Handles "City, Country" or "City, State" order based on length.
 * - Specifically collapses "Remote - USA" to "Remote".
 * - Handles plain "Remote" case.
 * - Returns other specific "Remote - X" variations (like "Remote - UK", "Remote - Canada") as is.
 * - Returns other formats (e.g., single names) as is after trimming.
 * - Returns null for invalid input (null, undefined, non-string, empty).
 */
const normalizeLocationName = (name) => {
    // 1. Input Validation & Trimming
    if (!name || typeof name !== 'string') {
        return null; // Handle null, undefined, non-string types
    }
    const trimmedName = name.trim();
    if (!trimmedName) {
        return null; // Handle empty strings or strings with only whitespace
    }

    // Use lowercase for case-insensitive comparisons
    const lowerTrimmedName = trimmedName.toLowerCase();

    // 2. Handle Remote Variations
    if (lowerTrimmedName.startsWith("remote")) {
        // Specific rule: Collapse "Remote - USA" to "Remote"
        if (lowerTrimmedName === "remote - usa") {
            return "Remote";
        }
        // Handle plain "Remote" case explicitly to ensure it returns "Remote"
        if (lowerTrimmedName === "remote") {
            return "Remote";
        }
        // Keep other specific variations like "Remote - UK", "Remote - Canada" as they are.
        // If the goal was to collapse *all* "Remote - X" to "Remote", you would simply:
        // if (lowerTrimmedName.startsWith("remote")) return "Remote";
        // But based on previous context, we keep specific ones distinct for now.
        return trimmedName; // Return "Remote - UK", "Remote - Canada", etc. as is
    }

    // 3. Handle "City, Country" or "City, State"
    // Split by comma, trim parts, and remove any empty strings resulting from split
    const parts = trimmedName.split(",").map(part => part.trim()).filter(Boolean);

    if (parts.length === 2) {
        const [part1, part2] = parts;
        // Use length comparison for consistent ordering (e.g., "City, State" or "City, Country")
        // Assumes the shorter part is the city/lesser region. Adjust if needed.
        return part1.length <= part2.length ? `${part1}, ${part2}` : `${part2}, ${part1}`;
    }

    // 4. Fallback for other formats (e.g., single city name, different separators)
    // Return the original trimmed name if it didn't match the patterns above.
    return trimmedName;
};

// --- Example Usage ---
// console.log(normalizeLocationName("Remote - USA"));      // Output: Remote
// console.log(normalizeLocationName("remote - usa "));     // Output: Remote
// console.log(normalizeLocationName("Remote"));           // Output: Remote
// console.log(normalizeLocationName("   Remote "));       // Output: Remote
// console.log(normalizeLocationName("Remote - UK"));      // Output: Remote - UK
// console.log(normalizeLocationName("Remote - Canada"));  // Output: Remote - Canada
// console.log(normalizeLocationName("New York, NY"));     // Output: NY, New York (or New York, NY depending on exact length if equal) -> Let's re-check length logic
// console.log(normalizeLocationName("NY, New York"));     // Output: NY, New York (or New York, NY) -> Corrected below
// console.log(normalizeLocationName("San Francisco, CA"));// Output: CA, San Francisco
// console.log(normalizeLocationName("London, UK"));       // Output: UK, London
// console.log(normalizeLocationName("UK, London"));       // Output: UK, London
// console.log(normalizeLocationName("Toronto"));          // Output: Toronto
// console.log(normalizeLocationName(" "));                // Output: null
// console.log(normalizeLocationName(null));             // Output: null
// console.log(normalizeLocationName(undefined));        // Output: null

// --- Let's refine the City, State/Country ordering slightly ---
// To ensure "City, State/Country" (longer name usually country/state)
const normalizeLocationName_RevisedOrder = (name) => {
    if (!name || typeof name !== 'string') return null;
    const trimmedName = name.trim();
    if (!trimmedName) return null;
    const lowerTrimmedName = trimmedName.toLowerCase();

    if (lowerTrimmedName.startsWith("remote")) {
        if (lowerTrimmedName === "remote - usa" || lowerTrimmedName === "remote") return "Remote";
        // Keep others specific
        return trimmedName;
    }

    const parts = trimmedName.split(",").map(part => part.trim()).filter(Boolean);
    if (parts.length === 2) {
        const [part1, part2] = parts;
        // If one part looks like a 2-letter state/province code, put it second
        if (/^[A-Z]{2}$/i.test(part1) && !/^[A-Z]{2}$/i.test(part2)) return `${part2}, ${part1}`;
        if (!/^[A-Z]{2}$/i.test(part1) && /^[A-Z]{2}$/i.test(part2)) return `${part1}, ${part2}`;
        // Otherwise, use length (longer one second - often country/state)
        return part1.length > part2.length ? `${part2}, ${part1}` : `${part1}, ${part2}`;
    }
    return trimmedName;
};

// console.log('--- Revised Order ---');
// console.log(normalizeLocationName_RevisedOrder("New York, NY"));     // Output: New York, NY
// console.log(normalizeLocationName_RevisedOrder("NY, New York"));     // Output: New York, NY
// console.log(normalizeLocationName_RevisedOrder("San Francisco, CA"));// Output: San Francisco, CA
// console.log(normalizeLocationName_RevisedOrder("London, UK"));       // Output: London, UK
// console.log(normalizeLocationName_RevisedOrder("UK, London"));       // Output: London, UK


// Choose ONE implementation - the revised one is slightly better for City, State/Country order.
// Using the revised one for the final answer:

const normalizeLocationName_Final = (name) => {
    if (!name || typeof name !== 'string') return null;
    const trimmedName = name.trim();
    if (!trimmedName) return null;
    const lowerTrimmedName = trimmedName.toLowerCase();

    // Handle Remote variations
    if (lowerTrimmedName.startsWith("remote")) {
        if (lowerTrimmedName === "remote - usa" || lowerTrimmedName === "remote") return "Remote";
        // Keep other specific remotes like Remote-UK, Remote-Canada as is
        return trimmedName;
    }

    // Handle City, State/Country
    const parts = trimmedName.split(",").map(part => part.trim()).filter(Boolean);
    if (parts.length === 2) {
        const [part1, part2] = parts;
        // Prefer "City, State/Country" order
        // If one looks like a 2-letter code, assume it's State/Province and put it second
        const isPart1Code = /^[A-Za-z]{2}$/.test(part1); // Relaxed to allow 'Uk' etc. if needed
        const isPart2Code = /^[A-Za-z]{2}$/.test(part2);
        if (isPart1Code && !isPart2Code) return `${part2}, ${part1}`; // e.g., "NY, New York" -> "New York, NY"
        if (!isPart1Code && isPart2Code) return `${part1}, ${part2}`; // e.g., "New York, NY" -> "New York, NY"

        // If neither/both are codes, use length (longer likely country/state, put second)
        // This handles "London, United Kingdom" vs "United Kingdom, London"
        return part1.length > part2.length ? `${part2}, ${part1}` : `${part1}, ${part2}`;
    }

    // Fallback
    return trimmedName;
};

function JobCategoryPage(props) {
    const { setLoading } = props;
    const a = props.location.pathname.split('/');
    let initialFilter = null;
    if (a && a.length > 2 && a[2].length > 0) {
        initialFilter = a[2];
    }

    const [capabilities, selectCapabilities] = React.useState(["All Careers"]);
    const [locations, selectLocations] = React.useState(["All Locations"]);
    const [allLocations, setAllLocationOptions] = React.useState({});
    const [allCapabilities, setAllCapabilityOptions] = React.useState({});
    const [allJobs, setAllJobs] = React.useState([]);
    const [currentJobs, setCurrentJobs] = React.useState([]);

    const [scrollTop, setScrollTop] = React.useState(false);
    const [scrollThresh, setScrollThresh] = React.useState(0);
    const [threshold, setThreshold] = React.useState(0);

    // For search prototype
    const [searchedJob, setSearchedJob] = React.useState('');
    const [totalJobs, setTotalJobs] = React.useState([]);

    const locationRef = React.useRef(null);
    const filterRef = React.useRef(null);

    /************************************************
     * SCROLL LISTENER
     ***********************************************/
    React.useEffect(() => {
        // We set threshold for applying the "sticky" style
        // to the filter card after scrolling past a certain point
        setThreshold(locationRef.current.offsetTop);

        const handleScroll = () => {
            setScrollTop(scrollThresh < window.scrollY);
            setScrollThresh(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll, true);
        return () => {
            window.removeEventListener('scroll', handleScroll, true);
        };
    }, [threshold, scrollThresh, scrollTop]);

    /************************************************
     * UTILS TO UPDATE STATE
     ***********************************************/
    const updateLocationHelper = (option, allLocsRef, locsRef) => {
        const allLocs = JSON.parse(JSON.stringify(allLocsRef));
        let locs = [...locsRef];

        // If we only have "All Locations", remove that first before adding anything
        if (locs.includes("All Locations") && locs.length < 2) {
            locs = [];
            allLocs["All Locations"].checked = false;
        }

        // If user selects "All Locations" from scratch
        if (option === "All Locations") {
            locs = [];
            Object.keys(allLocs).forEach((country) => {
                allLocs[country].checked = false;
                allLocs[country].cities.forEach((city) => {
                    city.checked = false;
                });
            });
        }

        // Check if it is a city or a country
        if (allLocs[option]?.cities) {
            // option is a country
            if (option === "All Locations") {
                if (allLocs[option].checked) {
                    const idx = locs.indexOf("All Locations");
                    if (idx !== -1) {
                        locs.splice(idx, 1);
                    }
                } else {
                    locs.push("All Locations");
                }
            } else {
                // toggling a country (all its cities)
                if (allLocs[option].checked) {
                    // uncheck all its cities
                    allLocs[option].cities.forEach((city) => {
                        city.checked = false;
                        const cityIdx = locs.indexOf(city.name);
                        if (cityIdx !== -1) {
                            locs.splice(cityIdx, 1);
                        }
                    });
                } else {
                    // check all its cities
                    allLocs[option].cities.forEach((city) => {
                        city.checked = true;
                        locs.push(city.name);
                    });
                }
            }
            allLocs[option].checked = !allLocs[option].checked;
        } else {
            // This is an actual city object
            allLocs[option.country].checked = true;
            allLocs[option.country].cities.forEach((element) => {
                if (element.city === option.city) {
                    element.checked = !element.checked;
                }
            });

            // If all cities in a country are unchecked, uncheck the country
            if (allLocs[option.country].cities.every((obj) => obj.checked === false)) {
                allLocs[option.country].checked = false;
            }

            if (!option.checked) {
                locs.push(option.name);
            } else {
                const index = locs.indexOf(option.name);
                if (index !== -1) {
                    locs.splice(index, 1);
                }
            }
        }

        // If there are no locations selected, fallback to "All Locations"
        if (locs.length === 0) {
            locs = ["All Locations"];
            allLocs["All Locations"].checked = true;
        }
        return [allLocs, locs];
    };

    const updateCapabilityHelper = (option, allCapsRef, capsRef) => {
        const allCaps = JSON.parse(JSON.stringify(allCapsRef));
        let caps = [...capsRef];

        // If user chooses "All Careers", reset everything
        if (option === "All Careers") {
            caps = ["All Careers"];
            Object.keys(allCaps).forEach((i) => {
                allCaps[i].checked = false;
            });
            allCaps["All Careers"].checked = true;
            return [allCaps, caps];
        }

        // If we currently only have "All Careers", remove that first
        if (caps[0] === "All Careers" && caps.length === 1) {
            caps = [option];
            allCaps["All Careers"].checked = false;
        } else {
            // Toggle
            const index = caps.indexOf(option);
            if (index !== -1) {
                caps.splice(index, 1);
            } else {
                caps.push(option);
            }
        }

        // If user unselects everything, revert to "All Careers"
        if (caps.length === 0) {
            caps = ["All Careers"];
            allCaps["All Careers"].checked = true;
        }

        allCaps[option].checked = !allCaps[option].checked;
        return [allCaps, caps];
    };

    /************************************************
     * CLEAR FILTERS
     ***********************************************/
    const clearFilters = () => {
        // Resets to "All Careers" + "All Locations"
        selectCapabilities(["All Careers"]);
        selectLocations(["All Locations"]);

        // Reset location checkboxes
        const resetLocs = JSON.parse(JSON.stringify(allLocations));
        Object.keys(resetLocs).forEach((loc) => {
            resetLocs[loc].checked = false;
            resetLocs[loc].cities.forEach((city) => {
                city.checked = false;
            });
        });
        resetLocs["All Locations"].checked = true;
        setAllLocationOptions(resetLocs);

        // Reset capability checkboxes
        const resetCaps = JSON.parse(JSON.stringify(allCapabilities));
        Object.keys(resetCaps).forEach((cap) => {
            resetCaps[cap].checked = false;
        });
        resetCaps["All Careers"].checked = true;
        setAllCapabilityOptions(resetCaps);
    };

    /************************************************
     * PREP LOCATION DATA: CITY + COUNTRY DE-DUPLICATION
     ***********************************************/
    const normalizeLocations = (locations) => {
        const allLocs = {};
        // We'll store sets of jobIndexes for each country
        // so we can avoid double-counting the same job in multiple cities of one country.
        const countryToJobsMap = {};

        // "All Locations" placeholder
        allLocs["All Locations"] = { cities: [], checked: false };

        // Build out city arrays
        for (let i = 0; i < locations.length; i++) {
            let country = locations[i].country;
            let city = locations[i].city;
            let name = locations[i].name;
            const state = locations[i].state;

            // This is the number of jobs in that city:
            const count = locations[i].city_count || locations[i]?.count || 0;
            // We'll also look for the jobIndexes set if it exists
            const jobIndexes = locations[i]?.jobIndexes || [];

            // Convert "Remote - USA" etc. to a consistent country & city name
            if (name.includes("Remote - USA")) {
                country = "United States of America";
                name = "Remote";
            }
            if (name.includes("Remote - UK")) {
                country = "United Kingdom";
                name = "Remote - UK";
            }
            if (name.includes("Remote - Canada")) {
                country = "Canada";
                name = "Remote - Canada";
            }

            if (country === "") continue;

            // Initialize if new country
            if (!allLocs[country]) {
                allLocs[country] = {
                    cities: [],
                    checked: false,
                    // Will be set after we compute unique job counts for the country
                    count: 0,
                };
            }

            // Insert the city at front if it includes "Remote", for user convenience
            if (name.includes("Remote")) {
                allLocs[country].cities.unshift({
                    city,
                    value: locations[i],
                    checked: false,
                    country,
                    state,
                    name,
                    count,
                    jobIndexes,
                });
            } else {
                allLocs[country].cities.push({
                    city,
                    value: locations[i],
                    checked: false,
                    country,
                    state,
                    name,
                    count,
                    jobIndexes,
                });
            }

            // Build or append to the set for the country
            if (!countryToJobsMap[country]) {
                countryToJobsMap[country] = new Set();
            }
            // Add each job ID from the city to the country's set
            jobIndexes.forEach((jIdx) => countryToJobsMap[country].add(jIdx));
        }

        // Now compute final "country.count" from the size of each country's job set
        Object.keys(countryToJobsMap).forEach((country) => {
            allLocs[country].count = countryToJobsMap[country].size;
        });

        // If there's no initialFilter or it doesn't match anything,
        // default is "All Locations" checked
        if (initialFilter) {
            const parsed = initialFilter.split(':');
            if (parsed[0] === 'l' && parsed[1]) {
                // Might match "Remote", "Remote - Canada", "London", etc.
                let matchedCityObj = null;
                const cityName = parsed[1];

                // A quick pass to see if we have that city
                Object.keys(allLocs).forEach((c) => {
                    allLocs[c].cities.forEach((cityObj) => {
                        if (cityObj.name === cityName) {
                            matchedCityObj = cityObj;
                        }
                    });
                });
                // If we can't find it, default to "All Locations"
                if (!matchedCityObj) {
                    matchedCityObj = "All Locations";
                }

                // Update state to reflect that city
                const result = updateLocationHelper(matchedCityObj, allLocs, []);
                setAllLocationOptions(result[0]);
                selectLocations(result[1]);
                return;
            } else {
                // "All Locations" is default
                allLocs["All Locations"].checked = true;
            }
        } else {
            // "All Locations" is default
            allLocs["All Locations"].checked = true;
        }

        setAllLocationOptions(allLocs);
    };

    const normalizeCapabilities = (capabilities) => {
        const allCaps = {};
        allCaps["All Careers"] = { cities: [], checked: false };

        // Sort so "All Careers" remains top if present, else alphabetical
        const sortedCaps = capabilities.sort((a, b) => {
            if (a.name === b.name) return 0;
            if (a.name === 'All Careers') return -1;
            if (b.name === 'All Careers') return 1;
            return a.name < b.name ? -1 : 1;
        });

        sortedCaps.forEach((cap) => {
            allCaps[cap.name] = {
                cities: [],
                checked: false,
                count: cap.count,
            };
        });

        if (initialFilter) {
            const parsed = initialFilter.split(':');
            if (parsed[0] === 'c' && allCaps[parsed[1]]) {
                const result = updateCapabilityHelper(parsed[1], allCaps, []);
                setAllCapabilityOptions(result[0]);
                selectCapabilities(result[1]);
                return;
            } else {
                allCaps["All Careers"].checked = true;
            }
        } else {
            allCaps["All Careers"].checked = true;
        }

        setAllCapabilityOptions(allCaps);
    };

    /************************************************
     * FETCH + PARSE JOB DATA
     ***********************************************/
    React.useEffect(() => {
        if (DynamicContent) {
            fetch(r3cPageContent + '?route=/job-category')
                .then((response) => response.json())
                .then((pgData) => {
                    if (!pgData.locations) {
                        pgData.locations = [];
                    }

                    // Clean up jobs
                    for (let i = 0; i < pgData.jobs.length; i++) {
                        // standardize "Remote - USA" => "Remote"
                        if (pgData.jobs[i].primaryLocation === "Remote - USA") {
                            pgData.jobs[i].primaryLocation = "Remote";
                        }
                        // Remove incomplete or null-locations
                        if (
                            !pgData.jobs[i].primaryLocation ||
                            pgData.jobs[i].primaryLocation === "Remote - CT"
                        ) {
                            pgData.jobs.splice(i, 1);
                            i--;
                            continue;
                        }
                        // Split the "locations" field if present
                        if (pgData.jobs[i].locations) {
                            pgData.jobs[i].locations = pgData.jobs[i].locations.split(';');
                        }
                    }

                    setAllJobs(pgData.jobs);
                    // For search prototype
                    setTotalJobs(pgData.jobs);

                    /**
                     * 1) Build a dictionary { cityName -> Set of jobIndexes }
                     *    So city_count = that set's size.
                     * 2) Then attach city_count to each "location" object in `pgData.jobLocations`.
                     */
                    let cityJobsMap = {};
                    // We'll also keep track of [jobIndex -> locationName[]] or direct parsing
                    pgData.jobs.forEach((job, idx) => {
                        // Each job can have multiple distinct locations
                        let locs = job.locations || [job.primaryLocation];
                        locs.forEach((loc) => {
                            const normalizedLoc = normalizeLocationName(loc.trim());
                            if (!cityJobsMap[normalizedLoc]) {
                                cityJobsMap[normalizedLoc] = new Set();
                            }
                            cityJobsMap[normalizedLoc].add(idx);
                        });
                    });

                    // Build an array with city + city_count
                    let locationArray = Object.entries(cityJobsMap).map(([name, jobSet]) => {
                        return {
                            name,
                            count: jobSet.size,
                            // keep track of which jobs are in this city for further country-level use
                            jobIndexes: [...jobSet],
                        };
                    });

                    console.log({ locationArray })
                    // Next, we update pgData.jobLocations by matching them to locationArray
                    // so that each location item has city_count + jobIndexes
                    let newArray = pgData.jobLocations.map((location) => {
                        const normalizedName = normalizeLocationName(location.name);
                        const updatedCity = locationArray.find(
                            (locObj) => normalizeLocationName(locObj.name) === normalizedName
                        );
                        if (updatedCity) {
                            return {
                                ...location,
                                city_count: updatedCity.count,
                                jobIndexes: updatedCity.jobIndexes || [],
                            };
                        }
                        return {
                            ...location,
                            city_count: 0,
                            jobIndexes: [],
                        };
                    });

                    // We remove "Creative" from capabilities if needed
                    let idxCreative = pgData.capabilities?.findIndex((x) => x.name === "Creative");
                    if (idxCreative > -1) {
                        pgData.capabilities.splice(idxCreative, 1);
                    }

                    // Combine capabilities + filters
                    let allCaps = [...pgData.capabilities, ...pgData.filters];

                    // Finally, we can feed `newArray` into `normalizeLocations` so that we
                    // can build the final structure of countries & their city arrays.
                    normalizeLocations(newArray);
                    normalizeCapabilities(allCaps);

                    setLoading(false);
                });
        } else {
            // If DynamicContent is not available, just load a dummy array
            setAllJobs([
                {
                    detailLink: "/job-detail",
                    date: "Posted: January 1, 2020",
                    primaryLocation: "London",
                    department: "Integrated Communications",
                    title: "Senior Account Manager",
                },
            ]);
        }
        // eslint-disable-next-line
    }, [initialFilter]);

    /************************************************
     * UPDATE CURRENT JOBS EVERY TIME FILTERS CHANGE
     ***********************************************/
    React.useEffect(() => {
        // Re-scroll the job‐results container to top
        let matchedJobs = [];

        // For every chosen capability + every chosen location,
        // gather matching jobs
        for (let capIdx = 0; capIdx < capabilities.length; capIdx++) {
            let capability = capabilities[capIdx];
            for (let locIdx = 0; locIdx < locations.length; locIdx++) {
                let location = locations[locIdx];
                for (let j = 0; j < allJobs.length; j++) {
                    const job = allJobs[j];
                    const isAllLocations = location === 'All Locations';
                    const isAllCareers = capability === 'All Careers';


                    
                    const inLocation =
                        isAllLocations ||
                        job.primaryLocation === location ||
                        (job.locations?.map(normalizeLocationName) || []).includes(
                            normalizeLocationName(location)
                        );

                    // The job's "bucket" or "filterBucket" can match the chosen capability
                    const inCapability =
                        isAllCareers ||
                        job.bucket === capability ||
                        job.filterBucket === capability;

                    if (inLocation && inCapability && !matchedJobs.includes(job)) {
                        matchedJobs.push(job);
                    }
                }
            }
        }

        setCurrentJobs(matchedJobs);
    }, [allJobs, capabilities, locations]);

    /************************************************
     * SEARCH INPUT HANDLERS
     ***********************************************/
    const handleSearch = (e) => {
        let searchedTerm = e.target.value.toLowerCase();
        setSearchedJob(searchedTerm);

        let result = [];
        if (searchedTerm.length > 2) {
            result = totalJobs.filter((job) => {
                if (job.title?.toLowerCase().includes(searchedTerm)) return true;
                if (job.department?.toLowerCase().includes(searchedTerm)) return true;
                if (job.primaryLocation?.toLowerCase().includes(searchedTerm)) return true;
                if (job.departmentDescription?.toLowerCase().includes(searchedTerm)) return true;
                return false;
            });
        } else {
            result = totalJobs;
        }
        setAllJobs(result);
    };

    const scrollToJobResults = () => {
        document.activeElement.blur();
        let jobResults = document.getElementById('job-results');
        let position = jobResults.getBoundingClientRect().top;
        let offset = 71;
        window.scrollTo({
            left: 0,
            top: position - offset,
            behavior: 'smooth',
        });
    };

    const handleSearchClick = () => {
        if (window.innerWidth < 992) {
            scrollToJobResults();
        } else {
            // just focus the input
            document.getElementById('search').focus();
        }
    };

    const handleKeyDown = (e) => {
        if (window.innerWidth < 992 && e.key === 'Enter') {
            scrollToJobResults();
        }
    };

    /************************************************
     * UPDATE SELECTED (UI) LOCATIONS/CAPABILITIES
     ***********************************************/
    const updateSelectedCity = (option) => {
        const [locsObj, locsArr] = updateLocationHelper(option, allLocations, locations);
        setAllLocationOptions(locsObj);
        selectLocations(locsArr);
    };

    const updateSelectedCountry = (option) => {
        const [locsObj, locsArr] = updateLocationHelper(option, allLocations, locations);
        setAllLocationOptions(locsObj);
        selectLocations(locsArr);
    };

    const updateSelectedCapability = (option) => {
        const [capsObj, capsArr] = updateCapabilityHelper(option, allCapabilities, capabilities);
        setAllCapabilityOptions(capsObj);
        selectCapabilities(capsArr);
    };


    /************************************************
     * RENDER
     ***********************************************/
    return (
        <JobCategoryPageWrap className="pageWrapper">
            <WideScreenContainer>
                <HeaderContainer>
                    {/* GPTW certification */}
                    <BadgeContainer>
                        <a
                            href="http://www.greatplacetowork.com/certified-company/7038366"
                            title="Rating and Review"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src="/images/gptw-2024.svg" alt="Review" width="100" />
                        </a>
                    </BadgeContainer>

                    <TitleContainer padding="1.5em 6.5em .5em">
                        <TitleXl textAlign="center">
                            All job opportunities<span className="magenta">.</span>
                        </TitleXl>
                        <p className="title-copy">
                            Not seeing an open role that's right for you? Join our talent community
                            and we'll keep you informed of career opportunities and events matching
                            your interests.
                        </p>
                    </TitleContainer>

                    <ViewAllContainer>
                        <ViewAll
                            target="_blank"
                            link={
                                'https://w2o.wd1.myworkdayjobs.com/en-US/RealChemistry/introduceYourself'
                            }
                            title={'STAY CONNECTED'}
                            borderColor="#FFF"
                            titleColor="#FFF"
                            hoverBackground="#FFF"
                            width="275px"
                            height="38px"
                            svgColor="#FFF"
                        />
                    </ViewAllContainer>

                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                        <BodySm
                            className="intro-text"
                            style={{ margin: '20px 0', color: 'white', fontSize: '20px' }}
                        >
                            <Link to={"/scam-alert"}>
                                <span style={{ color: '#ADD8E6' }}>Read more </span>
                            </Link>
                            about our commitment to safe online recruiting.
                        </BodySm>
                    </div>

                    {/* Search Bar */}
                    <SearchGroup>
                        <SearchBox>
                            <SearchBtn
                                onClick={handleSearchClick}
                                backgroundImage={'/images/search-icon-white.svg'}
                                alt="white magnifying glass icon"
                                aria-label="Search button for job listings on this site"
                                type="button"
                                name="Search button"
                            />
                            <SearchInput
                                id="search"
                                type="search"
                                aria-label="Search for job listings on this site"
                                placeholder="Search by role or by keyword"
                                name="job-search"
                                min="3"
                                value={searchedJob}
                                onChange={handleSearch}
                                onKeyDown={handleKeyDown}
                            />
                        </SearchBox>
                    </SearchGroup>

                    <Link to={"/careers"}>
                        <BackArrow className="cta arrow back">
                            <BackArrowSvg />
                            <TitleXs textTransform={"uppercase"} margin={"0 0 0 15px"}>
                                BACK TO CAREERS
                            </TitleXs>
                        </BackArrow>
                    </Link>
                </HeaderContainer>

                <Row className="job-filter-wrap">
                    {/* Left Column: Filters */}
                    <Col5 className="job-search-section">
                        <CategoryFilterSection ref={filterRef}>
                            <TitleLg>FILTER BY</TitleLg>

                            <ResetButton
                                type="button"
                                className={
                                    searchedJob.length > 2 || currentJobs.length < allJobs.length
                                        ? "searched"
                                        : ""
                                }
                                style={{ textTransform: "uppercase" }}
                                onClick={clearFilters}
                            >
                                Clear filters
                            </ResetButton>

                            <CollapsibleCheckboxArea
                                type="Career"
                                handleDropdownSelect={updateSelectedCapability}
                                title="Career Focus"
                                options={allCapabilities}
                            />

                            <CollapsibleCheckboxArea
                                type="Location"
                                handleOnInputChange={updateSelectedCity}
                                handleDropdownSelect={updateSelectedCountry}
                                title="Location"
                                options={allLocations}
                            />
                        </CategoryFilterSection>
                    </Col5>

                    {/* Right Column: Job Listings */}
                    <Col7
                        ref={locationRef}
                        className="job-right"
                        style={{ height: filterRef.current?.clientHeight }}
                    >
                        <FilterCard scrollTop={scrollTop}>
                            <BodySm color="#fff" textTransform="uppercase">
                                {locations.join(', ')}
                            </BodySm>
                            <BodySm color="#fff" textTransform="uppercase">
                                {capabilities.join(', ')}
                            </BodySm>
                        </FilterCard>

                        <BadgesRow
                            id="job-results"
                            className={
                                searchedJob.length > 2 || currentJobs.length < allJobs.length
                                    ? "searched"
                                    : ""
                            }
                        >
                            {searchedJob.length > 2 && (
                                <BodySm>Searched for: "{searchedJob}"</BodySm>
                            )}
                            <TitleSm style={{ textTransform: "uppercase", fontWeight: "400" }}>
                                <span
                                    style={{
                                        fontWeight: "900",
                                        paddingRight: "5px",
                                    }}
                                >
                                    {currentJobs.length > 0 ? currentJobs.length : ''}
                                </span>
                                {currentJobs.length !== 0 && searchedJob.length < 3
                                    ? 'Jobs Available'
                                    : ''}
                                {searchedJob.length > 2 && currentJobs.length !== 0
                                    ? ' Job Search Results'
                                    : ''}
                                {currentJobs.length === 0 ? 'No Results Found' : ''}
                            </TitleSm>
                        </BadgesRow>

                        <CategorySectionRight className="listings">
                            {currentJobs.map((item, index) => {

                                const formatLocation = (location) => {
                                    if (!location || typeof location !== 'string') return '';

                                    // Handle special 'Remote' patterns
                                    if (/remote/i.test(location)) {
                                        const match = location.match(/Remote(?:\s*-\s*(.+))?/i);
                                        const country = match && match[1] ? match[1].trim() : '';
                                        return country ? `Remote - ${country}` : 'Remote';
                                    }

                                    // Handle 'City, Country' or 'Country, City'
                                    const parts = location.split(',').map(p => p.trim());


                                    if (parts.length === 2) {
                                        const [part1, part2] = parts;
                                        return part1.length >= part2.length ? `${part1}, ${part2}` : `${part2}, ${part1}`;
                                    }

                                    // If not matching, return as is
                                    return location;
                                };

                                /**
                                 * Helper function to construct a unique, pipe-separated jobLocation string
                                 * Filters out duplicates and ensures "Remote" appears only once.
                                 */
                                const constructJobLocation = (department, primaryLocation, locations) => {
                                    // Helper function to check if a string includes 'remote' (case-insensitive)
                                    const includesRemote = (str) => {
                                        return typeof str === 'string' && str.toLowerCase().includes('remote');
                                    };

                                    // Initialize an array with department and primaryLocation
                                    let jobLocationArray = [department, primaryLocation];

                                    // If locations exist, add them to the array
                                    if (locations && locations.length > 0) {
                                        jobLocationArray = jobLocationArray.concat(locations);
                                    }

                                    // Normalize the array for duplicate removal:
                                    // - Convert to lowercase for comparison
                                    // - Use a Set to track seen locations
                                    const seen = new Set();
                                    const filteredJobLocation = [];

                                    jobLocationArray.forEach((loc) => {
                                        if (typeof loc !== 'string') return; // Skip non-string entries

                                        const formattedLoc = formatLocation(loc);
                                        const normalizedLoc = formattedLoc.trim().toLowerCase();


                                        // Special handling for "Remote"
                                        if (includesRemote(loc)) {
                                            if (!seen.has('remote')) {
                                                filteredJobLocation.push('Remote'); // Add with proper casing
                                                seen.add('remote');
                                            }
                                            // Else, already added "Remote", skip duplicates
                                        } else {
                                            if (!seen.has(normalizedLoc)) {
                                                filteredJobLocation.push(normalizedLoc.trim());
                                                seen.add(normalizedLoc);
                                            }
                                            // Else, duplicate location, skip
                                        }
                                    });

                                    // Join the filtered array with ' | '
                                    return filteredJobLocation.join(' | ');
                                };

                                // Construct the jobLocation string using the helper function
                                const jobLocation = constructJobLocation(
                                    item.department,
                                    item.primaryLocation,
                                    item.locations
                                );

                                return (
                                    <JobPostCard
                                        borderLeft="none"
                                        jobLink={item.detailLink}
                                        jobLocation={jobLocation}
                                        jobTitle={item.title}
                                        key={`jobPostCard-${index}`} // Consider using a unique ID if available
                                        postDate={item.date}
                                    />
                                );
                            })}
                        </CategorySectionRight>

                    </Col7>
                </Row>
            </WideScreenContainer>

            <FooterAnimation
                svgDesktop="/images/purple-footer.svg"
                svgMobile="/images/purple-footer-mbl.svg"
                borderTop="none"
            />
        </JobCategoryPageWrap>
    );
}

export default LoadingHoc(JobCategoryPage);
